<template>
  <div class="background" :style="bgStyle">
    <slot />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  src: {
    default: '',
    required: false,
    type: String
  },
  darken: {
    type: Boolean
  }
})

const bgStyle = computed(() => {
  return props.darken ? `background: linear-gradient(0deg, rgba(32, 32, 32, 0.4), rgba(32, 32, 32, 0.1)), url(${props.src});` : `background-image: url('${props.src}');`
})
</script>

<style scoped>
.background{
  background-position: center !important;
  background-size: cover !important;
}
</style>
